:root {
  --pinkmomo: #a50064;
  --pink: #d82f8b;
  --pinklight: rgb(255 240 246);
  --mylightgray: rgb(250, 250, 250);
  --gray: rgb(123, 127, 154);
  --ghostwhite: #efeff1;
  --white: white;
  --frost-white: #eaedf1;
  --gainsboro: #f5f7f9;
  --green: #339933;
  --transparent-black: rgba(0, 0, 0, 0.1);
  --blue: #0071a9;
  --slate-gray: #21262c;
}
