@import "./styles/colors.css";

body {
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background-color: var(--gainsboro);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.responsive-image {
  max-width: 100%;
  height: auto;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.me-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.m-auto{
  margin: auto !important;
}

.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.d-block{
  display: block;
}
.d-flex {
  display: flex;
}
.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.direction-reverse {
  flex-direction: row-reverse;
}
.w-100 {
  width: 100% !important;
}
.upper {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}
.wrap{
  width: 100%;
}
.float-right{
  float:right !important;
}
.main-layout-sm {
  max-width: 375px;
  height: 100vh;
  background-color: white;
}
.main {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .main-layout-sm {
    max-width: 100%;
  }
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}
.box {
  /* border-radius: 6px; */
  background: var(--white);
  /* border: 1px solid var(--frost-white); */
  min-width: 300px;
  min-height: 500px;
  width: 100%;
}
.title {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  text-align: center;
}
.title-content{
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--slate-gray);
  word-break: break-word;
}
.content{
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--slate-gray);
  word-break: break-word;
}
.amount{
  color: var(--blue);
  font-size: 22px;
  line-height: 1.4;
  font-weight: 500;
}
@media (max-width: 767px) {
  .title {
    font-size: 16px;
  }
}
.logo{
  max-width: 150px;
}
.logo img{
  width: 100%;
  max-height: 32px;
}
.ant-form-item-explain-error{
  font-size: 12px !important;
}
.active-common{
  border: 1px solid var(--pink) !important;
}

.invoice-payment-qr-code {
  text-align: center;
  padding: 20px;
}



.qr-code-container {
  margin: 20px auto;
}

.qr-code-container svg {
  stroke: #000;
  fill: #000;
}

.qr-code-container svg path {
  stroke-width: 1px;
}

.qr-code-container img {
  /* Add your image styling here */
  max-width: 200px; /* Adjust as needed */
  display: block;
  margin: 0 auto;
}