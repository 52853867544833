@import "./colors.css";

.payment {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
.bank-logo {
  width: 70px;
  height: 30px;
}
.wrap-suggest-amout {
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 24px;
}
.custom-input-number{
  margin-bottom: 16px !important;
}
.suggest-amount {
  display: inline-block;
  width: auto;
  font-size: 11px;
  background: var(--ghostwhite);
  border-radius: 10px;
  margin: 4px;
  color: var(--gray);
  font-weight: 700;
  text-align: center;
  padding: 10px;
}
.suggest-amount:hover{
    cursor: pointer;
    background-color: beige;
}
