@import "colors.css";
.wrap-detail-payment {
  font-size: 14px;
}
.detail-payment {
  background-color: var(--gainsboro);
  padding: 10px;
  margin: 10px;
}
.warning{
  padding: 10px;
  margin: 10px;
  font-size: 13px;
  word-break: break-word;
}
.detail-payment_title {
  text-transform: uppercase;
}
.mute {
  color: var(--green);
  font-weight: bold;
}
.payment-id {
  color: var(--pink);
  font-weight: bold;
}
.payment-icon {
  width: 60px;
  height: 60px;
  margin-left: 2px;
  border-radius: 10px;
  background-color: var(--white);
}
.payment-method {
  width: 100%;
  background-color: var(--white);
  padding: 12px 16px;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 2px rgba(33, 38, 44, 0.16);
  border-radius: 3px;
  transition: 0.15s all linear;
  border: 0;
  box-sizing: border-box;
}
.payment-method:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(33, 38, 44, 0.16);
}
.time {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin: 10px;
  margin-top: 0px !important;

}
.time-text {
  color: #5a7087;
  font-size: 14px;
}
.label {
  font-size: 13px;
  color: #5a7087;
}
.value {
  word-break: break-word;
  font-size: 13px;
  font-weight: 500;
}
