@import "colors.css";

.header {
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--white);
  height: 68px;
  width: 100%;
  z-index: 99;
  padding-left: 0;
  padding-right: 0;
}
.content{
    margin: 16px;
    width: 100%;
}
