@import "./colors.css";

.wrap-time {
  display: flex;
  align-items: center;
  justify-self: center;
}
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21262c;
  color: white;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  max-width: 32px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
  box-sizing: border-box;
}
